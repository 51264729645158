<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        คลังสินค้า
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn
          depressed
          @click="$router.push({path: '/stock/po'})"
          color="primary"
          class="mr-4"
      >ดูรายการสั่งสินค้า
      </v-btn>
      <v-btn
          v-if="$store.state.authUser.user_permissions.includes('add_product')"
          depressed
          @click="$router.push({path: '/stock/product/create'})"
          color="success"
      >+ เพิ่มสินค้าใหม่
      </v-btn>
      <v-btn @click="showFilter = !showFilter" icon>
        <v-icon>mdi-filter</v-icon>
      </v-btn>
    </v-toolbar>
    <!--    <div class="mx-4 box box-2" style="min-height: 100%;">-->
    <!--      <v-row dense class="box box-2" style="height: 100%;display: flex;flex-direction: row">-->
    <!--        &lt;!&ndash;        <transition name="list-complete">&ndash;&gt;-->
    <!--        <v-col-->
    <!--            :cols="showFilter?9:12"-->
    <!--            style="height: 100%"-->
    <!--        >-->
    <div class="mr-4 box box-2" style="min-height: 100%;">
      <splitpanes style="height: 100%">
        <pane :size="showFilter ? 80 : 100" class="pl-4 pb-4">
          <v-card
              style="height: 100%"
              class=" rounded-lg elevation-2"
              outlined
              tile
          >
            <div id="table-warp" style="height: 100%;overflow: auto">
              <v-data-table
                  @click:row="goProductDetail"
                  loading-text="Loading... Please wait"
                  style="height: 100%"
                  :headers="headers"
                  :items="filteredProduct"
                  class="elevation-1"
                  no-data-text="ไม่มีข้อมูล"
                  :header-props="{ sortIcon: null }"
                  :items-per-page="itemsPerPage"
                  :footer-props="footerProps"
                  :search="search"
                  :custom-filter="customFilter"

              >
                <template
                    v-for="header in headers.filter((header) =>
                    header.hasOwnProperty('formatter')
                  )"
                    v-slot:[`item.${header.value}`]="{ header, value }"
                >
                  {{ header.formatter(value) }}
                </template>
                <template v-slot:item.hasCom="{ item }">
                  <div>
                    <v-checkbox disabled v-model="item.hasCom"></v-checkbox>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </pane>
        <!--        </v-col>-->
        <!--        </transition>-->
        <!--        <transition name="list-complete">-->
        <!--        <v-col-->
        <!--            v-if="showFilter"-->
        <!--            style="height: 100%"-->

        <!--        >-->
        <pane :size="showFilter ? 20 : 0" class="pb-4">
          <FilterCard @search="loadProduct"
                      :search-option="[{text:'รหัสสินค้า',value:'id_display'},{text:'ชื่อสินค้า',value: 'product_name'}]"
                      category-name
          />
        </pane>
      </splitpanes>
    </div>
    <!--        </v-col>-->
    <!--        &lt;!&ndash;        </transition>&ndash;&gt;-->
    <!--      </v-row>-->
    <!--    </div>-->
    <!--    </transition>-->
  </div>
</template>

<script>
import axios from 'axios'
import {Splitpanes, Pane} from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import FilterCard from "@/components/FilterCard";

export default {
  name: "Stock",
  components: {FilterCard, Splitpanes, Pane},
  data() {
    return {
      search: '',
      products: [],
      headers: [
        {
          text: 'รหัสสินค้า',
          value: 'id_display',
          width: 140,
          align: 'center'
        },
        {
          text: 'ชื่อสินค้า',
          value: 'name',
          align: 'left'
        },
        {
          text: 'หมวดหมู่',
          value: 'category.name',
          align: 'center',
          width: 200,
        },
        {
          text: 'ราคามารับ',
          value: 'price',
          width: 120,
          align: 'center'
        },
        {
          text: 'ราคาส่งเอง',
          value: 'price_for_ship',
          width: 120,
          align: 'center'
        },
        {
          text: 'คงเหลือ',
          value: 'stock_location',
          width: 80,
          align: 'center',
          formatter: this.stockBalance
        },
        {
          text: 'หน่วย',
          value: 'unit',
          width: 100,
          align: 'center'
        },

        {
          text: 'มีการคิดค่าตัก',
          value: 'hasCom',
          width: 120,
          align: 'center'
        },
      ],
      itemsPerPage: 0,
      footerProps: {'items-per-page-options': []},
      optionSearch: '',
      categoryFilterRadio: 'ทั้งหมด',
      categoryRadio: [],
      showFilter: true
    }
  },
  created() {
    this.getCategory()
    // this.loadProduct()
  },
  methods: {
    customFilter(value, search, item) {
      console.log(this.search, this.optionSearch)
      search = this.search.toString().toLowerCase()
      return item[this.optionSearch].toString().includes(search)
    },
    getCategory() {
      axios.get('/api/stocks/category/').then(res => {
        this.categoryRadio = res.data.data
      })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
    },
    loadProduct(query) {
      this.$store.state.isLoading = true
      axios.get('/api/stocks/product/' + query)
          .then(res => {
            this.products = res.data.data
            // this.filteredProduct = res.data.data
            this.$store.state.isLoading = false
            this.updateTable();
          })
          .catch(e => this.$store.state.snackbar = {
            text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
            visible: true,
            color: 'error'
          })
          .finally(() => {
            this.$store.state.isLoading = false
          })
    },
    updateTable() {
      let tableHeight = document.getElementById('table-warp').offsetHeight;
      this.itemsPerPage = ((tableHeight - 48) / 67) - 1 | 0;
      if (this.filteredProduct.length < this.itemsPerPage) this.itemsPerPage = this.filteredProduct.length;
      this.footerProps['items-per-page-options'].unshift(this.itemsPerPage);
    },
    stockBalance(stocks) {
      return (stocks.reduce((a, b) => {
        return a + b.amount
      }, 0)).toFixed(3)
    },
    goProductDetail(row) {
      this.$router.push(`/stock/product/${row.id}`)
    }
  },
  computed: {
    filteredProduct() {
      if (this.categoryFilterRadio === 'ทั้งหมด') {
        return this.products
      } else {
        return this.products.filter(p => {
          return p.category.name === this.categoryFilterRadio
        })
      }
    }
  },
  watch: {
    // filteredProduct(value) {
    //   console.log(value)
    //   this.updateTable()
    // }
    // categoryFilterRadio(value) {
    //   console.log(value)
    //   this.optionSearch = 'category.name'
    //   this.search = value
    //
    // }
  }
}
</script>

<style scoped>
.list-complete-item {
  transition: all 3s;
  /*display: inline-block;*/
}

.list-complete-enter, .list-complete-leave-to
  /* .list-complete-leave-active below version 2.1.8 */
{
  /*transform: translateY(30px);*/
}

.list-complete-leave-active {
  /*position: absolute;*/
}

/*.list-complete2-item {*/
/*  transition: all 3s;*/
/*  !*display: inline-block;*!*/
/*  width: 50px;*/
/*}*/

/*.list-complete2-enter, .list-complete2-leave-to*/
/*  !* .list-complete-leave-active below version 2.1.8 *!*/
/*{*/

/*  width: 60px;*/
/*  !*transform: translateY(30px);*!*/
/*}*/

/*.list-complete2-leave-active {*/
/*  !*position: absolute;*!*/
/*  width: 60px;*/
/*}*/
</style>



